import 'src/pages/maintenance/index.scss';
import React from 'react';
import { useConfig } from 'src/hooks';

export default () => {
  const { maintenanceMessage } = useConfig();

  const title = 'We’re making improvements';

  return (
    <div className='maintenance'>
      <h1 className='maintenance--title'>{title}</h1>
      <div className='maintenance--message'>{maintenanceMessage}</div>
    </div>
  );
};
